<template>
  <div class="article-comments block">
    <Preloader v-if="pending" />
    <div class="block-wrapper article-page-block">
      <h2>Comments</h2>
      <button v-if="user.role !== 'USER' && comments.length > 0" class="article-page-edit" @click="deleteComments">
        Delete all comments
      </button>
    </div>
    <div class="article-comments-text-container">
      <span ref="textbox" role="textbox" contenteditable class="article-comments-text"></span>
      <div class="article-comments-btn-container">
        <div v-if="reply" @click="setReply(null)" class="article-comments-reply-container">
          <p class="article-comments-reply-title">Reply to:</p>
          <p class="article-comments-reply-text">{{reply.comment}}</p>
        </div>
        <button @click="sendComment" class="btn blue">Post comment</button>
      </div>
    </div>
    <simplebar class="article-comments-container">
      <ul class="article-comments-list" v-if="commentsWithoutReply && commentsWithoutReply.length > 0">
        <li class="article-comments-item" v-for="(comment, idx) in commentsWithoutReply" :key="`comment-${idx}`">
          <div class="article-comments-item-header df">
            <p>{{ comment.author_email }}</p>
            <p class="date">{{ $moment(comment.created_at).format('DD MMMM YYYY') }}</p>
            <button class="article-comments-reply" @click="setReply(comment)">Reply</button>
          </div>
          <p class="article-comments-item-text">{{ comment.comment }}</p>
          <div v-if="getCommentReply(comment.id).length > 0" class="article-comments-reply-list">
            <p class="article-comments-reply-title">Replied:</p>
            <ul class="article-comments-list" >
              <li class="article-comments-item" v-for="(comment, idx) in getCommentReply(comment.id)" :key="`comment-${idx}`">
                <div class="article-comments-item-header df">
                  <p>{{ comment.author_email }}</p>
                  <p class="date">{{ $moment(comment.created_at).format('DD MMMM YYYY') }}</p>
                </div>
                <p class="article-comments-item-text">{{ comment.comment }}</p>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </simplebar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Preloader from '@/components/common/Preloader';
import {eventBus} from '@/main';

export default {
  name: "ArticleComments",
  components: {Preloader},
  props: ['id', 'disabled'],
  data(){
    return {
      pending: false,
      comments: [],
      reply: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    commentsWithoutReply(){
      return this.comments.filter(c => !c.in_reply_to)
    }
  },
  watch: {
    async id(state, prevState) {
      if(state && !prevState){
        await this.getComments();
      }
    }
  },
  methods: {
    ...mapActions(['getArticleComments', 'setArticleComment', 'deleteArticleComments']),
    getCommentReply(id){
      return this.comments.filter(c => c.in_reply_to === id);
    },
    async sendComment() {
      const comment = this.$refs.textbox.textContent;
      if(comment.length > 0 && !this.disabled) {
        this.pending = true;
        await this.setArticleComment({
          comment,
          in_reply_to: this.reply ? this.reply.id : null,
          id: this.id
        }).then(async () => {
          this.$refs.textbox.textContent = '';
          this.setReply(null);
          await this.getComments();
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: 'Get comments',
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    setReply(reply){
      if(!this.disabled) {
        this.reply = reply;
      }
    },
    async getComments(){
      this.pending = true;
      await this.getArticleComments(this.id).then(data => {
        this.comments = data;
        this.pending = false;
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Get comments',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    async deleteComments(){
      this.pending = true;
      await this.deleteArticleComments(this.id).then(data => {
        this.comments = [];
        this.setReply(null);
        this.pending = false;
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Delete comments',
          text: error.response.data.message,
          type: 'error'
        });
      });
    }
  },
  async created() {
    eventBus.$on('getComments', async () => {
      await this.getComments();
    });
  }
}
</script>

<style scoped>

</style>
